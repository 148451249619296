import Col from "react-bootstrap/Col";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export default function Search(){
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate();
    return (
        <Col md="12" lg="12">
            <form onSubmit={e => {
                e.preventDefault();
                navigate(`/search/${searchText}`)
            }}>
            <input className="form-control" type="text" placeholder="Search" aria-label="Search"
                   onChange={e => setSearchText(e.target.value)} />
            </form>
        </Col>
    );
}

