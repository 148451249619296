import "./FoodCard.css";
import Card from "react-bootstrap/Card";
import nl2br from "react-newline-to-break";
import Col from "react-bootstrap/Col";
import {getFavourites} from "../../../apis/local-data";
import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";
import produce from "immer";

export default function FoodCard(props) {
    const [favourites, setNewFavourites] = useState(getFavourites());

    useEffect(() => {
        // storing input name
        localStorage.setItem("favourites", JSON.stringify(favourites));
    }, [favourites]);

    const {item} = props
    if (!item) {
        return null;
    }
    function addToFavourites(item) {
        setNewFavourites([...favourites, item])
    }
    function removeFromFavourites(item) {
        const toRemove = favourites.indexOf(item)
        const newFav = produce(favourites, draft => {
            draft.splice(toRemove, 1)
        })
        setNewFavourites(newFav);
    }

    function inFavourites(data) {
        return !!favourites.find(f => f.id === data.id)
    }

    const photo = item.photos && item.photos[0]
    return (
        <Col md={6} lg={4} className="pb-3"><Card className="card">
            <Card.Body>
                {photo && <Card.Img variant="bottom" className="card-image" src={process.env.REACT_APP_SERVER_URL + "/image/" + photo }/>}
                <Card.Title className="pt-3 pb-1">{inFavourites(item) ? "⭐" : "☆"}{item.origin}</Card.Title>
                <Card.Text>
                    {nl2br(item.text)}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                {!inFavourites(item) && <Button variant="secondary" onClick={() => addToFavourites(item)}>Add to Favourites</Button>}
                {inFavourites(item) && <Button variant="outline-secondary" onClick={() => removeFromFavourites(item)}>Remove from Favourites</Button>}
            </Card.Footer>
        </Card></Col>
    )
}
