import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useParams} from "react-router-dom";
import FoodCard from "../components/FoodCard/FoodCard";
import Search from "../components/Search";
import {getFoodData} from "../../apis/local-data";
import isEmpty from "lodash/isEmpty";

export default function SearchListings() {
    const { search } = useParams();
    const data = getFoodData();
    if (isEmpty(data)) {
        return (<Container className="p-3">
            <Row className="p-3 row-cols-md-auto justify-content-center">
                <Col><h1>PKU Foods</h1></Col>
            </Row>
            <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
            <Row className="p-3 row-cols-lg-auto justify-content-center">
                <Search />
            </Row>
            <Row className="row-cols-md-auto">
                Sorry, but there was a problem fetching the data from the server. Please refresh and try again.
            </Row>
        </Container>)
    }

    const resultsOfResults= [];
    for (const searchTerm of search.toLowerCase().split(" ")) {
        resultsOfResults.push(
            Object.values(data).flat().filter(t => t.text.toLowerCase().indexOf(searchTerm) !== -1))
    }
    const results = resultsOfResults.flat();
    const resultDom = []
    for (let item of results) {
        resultDom.push(<FoodCard item={item} />)
    }
    return (<Container className="p-3">
        <Row className="p-3 row-cols-md-auto justify-content-center">
            <Col><h1>PKU Foods</h1></Col>
        </Row>
        <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
        <Row className="p-3 row-cols-lg-auto justify-content-center">
            <Search />
        </Row>
        <Row className="row-cols-md-auto">
            {resultDom}
        </Row>
    </Container>)
}
