import merge from "lodash/merge";

export function getFoodData() {
    const existingString = localStorage.getItem("foods");
    return existingString ? JSON.parse(existingString) : {};
}

export function updateFoodData(newData) {
    const existing = getFoodData()
    const merged = merge(existing, newData);

    localStorage.setItem("foods", JSON.stringify(merged));
}

export function getFavourites() {
    const existingString = localStorage.getItem("favourites");
    return existingString ? JSON.parse(existingString) : [];
}
