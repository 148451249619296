import ASDA from '../../images/supermarket/Asda_logo.svg';
import ALDI from '../../images/supermarket/ALDI_SUD.png';
import HollandBarret from '../../images/supermarket/holland_barret.svg';
import MarksAndSparks from '../../images/supermarket/mands.svg';
import Morrisons from '../../images/supermarket/MorrisonsLogo.svg';
import Ocado from '../../images/supermarket/Ocado_Group_Logo.svg';
import Sainsburys from '../../images/supermarket/Sainsbury_s_Logo.svg';
import Tesco from '../../images/supermarket/Tesco_Logo.svg';
import TheVeganKind from '../../images/supermarket/thevegankind.svg';
import Waitrose from '../../images/supermarket/Waitrose_&_Partners.svg';

const titleToLogo = {
    "ASDA": ASDA,
    "Tesco": Tesco,
    "Waitrose": Waitrose,
    "Marks & Spencers": MarksAndSparks,
    "Morrisons": Morrisons,
    "Sainsbury's": Sainsburys,
    "ALDI":ALDI,
    "The Vegan Kind": TheVeganKind,
    "Ocado": Ocado,
    "Holland & Barret": HollandBarret
}

export default titleToLogo
