import "./LoadingSpinner.css"
import Spinner from 'react-bootstrap/Spinner';
import Container from "react-bootstrap/Container";

export default function LoadingSpinner() {
    return (
        <Container className="center">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </Container>
    );
}
