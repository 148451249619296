import './SupermarketCategory.css';

export default function SupermarketCategory(props) {
    const {imageSrc, title} = props;
    if (!imageSrc || !title) {
        return null
    }
    return (
        <div className="sc-container">
            <a href={"/supermarket/" + title}>
                <img className="supermarket-category" src={imageSrc} aria-label={title}  alt={title}/>
            </a>
        </div>
    );
}
