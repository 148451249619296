import "./SupermarketEntries.css"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useParams} from "react-router-dom";
import titleToLogo from "../../components/SupermarketMapping";
import FoodCard from "../../components/FoodCard/FoodCard";
import Search from "../../components/Search";
import isEmpty from "lodash/isEmpty";
import {getFoodData} from "../../../apis/local-data";

export default function SupermarketEntries() {
    const { supermarket } = useParams();
    const entries = []
    const data = getFoodData();
    if (isEmpty(data)) {
        return (
            <Container className="p-3">
                <Row className="p-3 row-cols-md-auto justify-content-center"><img className="supermarket-logo" src={titleToLogo[supermarket]} alt={supermarket} /></Row>
                <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
                <Row className="p-3 row-cols-lg-auto justify-content-center">
                    <Search />
                </Row>
                <Row>
                    Sorry, but there was a problem fetching the data from the server. Please refresh and try again.
                </Row>
            </Container>
        )
    }

    for (let item of data[supermarket]) {
        entries.push(<FoodCard item={item} />)
    }
    return (
        <Container className="p-3">
            <Row className="p-3 row-cols-md-auto justify-content-center"><img className="supermarket-logo" src={titleToLogo[supermarket]} alt={supermarket} /></Row>
            <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
            <Row className="p-3 row-cols-lg-auto justify-content-center">
                <Search />
            </Row>
            <Row>
                {entries}
            </Row>
        </Container>
    )
}
