import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Search from "../components/Search";

export default function SearchListingsEmpty() {
    return (<Container className="p-3">
        <Row className="p-3 row-cols-md-auto justify-content-center">
            <Col><h1>PKU Foods</h1></Col>
        </Row>
        <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
        <Row className="p-3 row-cols-lg-auto justify-content-center">
            <Search />
        </Row>
        <Row className="row-cols-md-auto">
            <Col>Please enter a search term and try again.</Col>
        </Row>
    </Container>)
}
