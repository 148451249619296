import {useQuery} from "react-query";
import {getFoodItems} from "../../apis/core-api";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import SupermarketCategory from "../components/SupermarketCategory/SupermarketCategory";
import titleToLogo from "../components/SupermarketMapping";
import Search from "../components/Search";
import {getFoodData, updateFoodData} from "../../apis/local-data";

export default function FoodApp() {
    const query = useQuery("foodList", getFoodItems);
    if (query.isLoading) {
        return <LoadingSpinner />
    }
    if (query.isError) {
        return (<Container className="p-3">
            <Row className="p-3 row-cols-md-auto justify-content-center">
                <Col><h1>PKU Foods</h1></Col>
            </Row>
            <Row className="p-3 row-cols-md-auto justify-content-center">
                <Col>Based on supermarkets</Col>
            </Row>
            <Row className="p-3 row-cols-lg-auto justify-content-center">
                <Search />
            </Row>
            <Row className="row-cols-md-auto">
                Sorry, but there was a problem fetching the data from the server. Please refresh and try again.
            </Row>
        </Container>)
    }

    updateFoodData(query.data);
    const data = getFoodData()

    const supermarkets=[];
    for (let s of Object.keys(data).sort()) {
        supermarkets.push(<Col><SupermarketCategory imageSrc={titleToLogo[s]} title={s} /></Col>)
    }
    return (
        <Container className="p-3">
            <Row className="p-3 row-cols-md-auto justify-content-center">
                <Col><h1>PKU Supermarket Foods</h1></Col>
            </Row>
            <Row className="p-3 row-cols-md-auto justify-content-center">
                <Col><a href="/favourites">Favourites</a> <a rel="me" href="https://pkutalk.com/@soheb">Mastodon</a></Col>
            </Row>
            <Row className="p-3 row-cols-lg-auto justify-content-center">
                <Search />
            </Row>
            <Row className="row-cols-md-auto">
                {supermarkets}
            </Row>
        </Container>
    );

}
