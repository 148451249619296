import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FoodCard from "../components/FoodCard/FoodCard";
import Search from "../components/Search";
import {getFavourites} from "../../apis/local-data";
import isEmpty from "lodash/isEmpty";

export default function Favourites() {
    const data = getFavourites();
    if (isEmpty(data)) {
        return (<Container className="p-3">
            <Row className="p-3 row-cols-md-auto justify-content-center">
                <Col><h1>PKU Foods</h1></Col>
            </Row>
            <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
            <Row className="p-3 row-cols-lg-auto justify-content-center">
                <Search />
            </Row>
            <Row className="row-cols-md-auto">
                <p>No favourites stored... Try going <a href="/">back</a> and clicking on the "Add to Favourites" button.</p>
                <p>You'll know if you've favourited something because the ☆ next to the username will turn ⭐️ when favourited.</p>
            </Row>
        </Container>)
    }

    const favourites = [];
    for (let item of data) {
        favourites.push(<FoodCard item={item} />)
    }
    return (<Container className="p-3">
        <Row className="p-3 row-cols-md-auto justify-content-center">
            <Col><h1>PKU Foods</h1></Col>
        </Row>
        <Row className="p-3 row-cols-md-auto justify-content-center"><a href="/">Back to listings</a></Row>
        <Row className="p-3 row-cols-lg-auto justify-content-center">
            <Search />
        </Row>
        <Row className="row-cols-md-auto">
            {favourites}
        </Row>
    </Container>)
}
