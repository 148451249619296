import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import FoodApp from "./ui/view/FoodApp";
import SupermarketEntries from "./ui/view/SupermarketEntries/SupermarketEntries";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import SearchListings from "./ui/view/SearchListings";
import SearchListingsEmpty from "./ui/view/SearchListingsEmpty";
import Favourites from "./ui/view/Favourites";

const queryClient = new QueryClient();

const router = createBrowserRouter([
    {
        path: "/",
        element: <FoodApp/>,
    },
    {
        path: "supermarket/:supermarket",
        element: <SupermarketEntries />,
    },
    {
        path: "search/:search",
        element: <SearchListings />,
    },
    {
        path: "search/",
        element: <SearchListingsEmpty />,
    },
    {
        path: "favourites",
        element: <Favourites />
    }
]);

function CoreApp() {
    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </React.StrictMode>
    )
}

export default CoreApp;
